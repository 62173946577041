.btn-light{
    color: rgba(0, 0, 0, 0.8);
}
.btn{
    font-variant: all-small-caps;
    border-radius: 10px;

    padding-left: 12px;
padding-right: 12px;
transition: .3s;
font-weight: 300;
padding-bottom: 4px;
padding-top: 3px;
}
hr{
    opacity: 0.05;
}

.alert{
backdrop-filter:blur(10px);
}

.alert-danger{
    background: rgba(249, 199, 199, 0.43);
    border-color: rgba(181, 108, 108, 0.5);
}


#root .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,#root .MuiDataGrid-root .MuiDataGrid-cell:focus{
    outline: none !important;
}
.form-control{
    border-radius: 10px;
    font-size: 14px;
    height: 32px;
}

small{
    font-size: 12px;
color: rgba(0,0,0,0.5);
font-weight: 300;
}

.btn-primary {
    color: #f9f2ea;
    background-color:#9e896e;
    border-color: #988160;
}
.btn-primary:hover {
    color: #f9f2ea;
    background-color: #c29b69;
    border-color: #ab7f46;
}
.btn-primary:active {
    color: white;
    background-color: #c2965c;
    border-color: #ab7f46;
}

.btn-danger {
    color: #772828;
background-color: transparent;
border-color: transparent;
}
.bg-danger{
    background-color: rgba(221, 53, 69, 0.14)!important;
    transition: .3s;
}

.btn-danger:hover {
    color: #772828;
background-color: rgba(255, 0, 0, 0.1);
border-color: transparent;
    }
    
    .MuiButton-root.MuiButton-textPrimary {
        background-color: rgba(25, 118, 210, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textPrimary:hover {
        background-color: rgba(25, 118, 210, 0.1) !important;
    }
    .MuiButton-root.MuiButton-textError {
        background-color: rgba(211, 47, 47, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textError:hover {
        background-color: rgba(211, 47, 47, 0.1) !important;
    }
    .MuiButton-root.MuiButton-textSuccess {
        background-color: rgba(46, 125, 50, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textSuccess:hover {
        background-color: rgba(46, 125, 50, 0.1) !important;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
        background-color: rgba(249, 249, 253, 0);
        
      }
      
      
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 1px solid white;
        background-color:  rgba(190, 190, 190, 0.8);
      }
      ::-webkit-scrollbar-track{
       
        background-color:  rgba(190, 190, 190, 0);
      }
      

      .hiddenScroll::-webkit-scrollbar-thumb{
        background-color:  rgba(190, 190, 190, 0);
        width: 1px;
        height: 1px;;
      }
      .hiddenScroll ::-webkit-scrollbar {
        width: 1px;
        background-color: rgba(249, 249, 253, 0);
      }
  body ul:focus,
  body:focus,
  body div:focus,
  body a:focus,
  body p:focus{
    outline: none !important;
  }